<template>
	<div :class="classes ? classes : ''">
		<template v-for="(field, key, i ) in new_val">
			<v-row v-if="tooltip" :key="i" class="mx-0">
				<v-tooltip top  >
					<template v-slot:activator="{ on}">
						<span v-on="on">{{ key }}</span>
					</template>
					<span class="caption-1 font-weight-bold" >{{ $tc('single_trad.ChangeField.becomes', 1, {change1: field[0], change2: field[1]} ) }}</span>
				</v-tooltip>
			</v-row>
			<v-row
					v-else-if="expanded"
					style="cursor:pointer"
					:key="i"
					class="mx-0"
					align="center"
					@click="() => {dialog = true; changeToShow = key}"
					no-gutters
			>
				<v-col class="text-truncate">
					<div class="text-truncate py-1" :title="key">{{ key }}</div>
				</v-col>
				<v-col cols="auto">
					<Button icon :toolTip="$tc('global.name.details', 2)" iconVal="mdi-information" iconSize="15" classes="ml-2" carre :styles="{maxHeight: '15px', minWidth: '15px', maxWidth: '15px' }" :click="() => {dialog = true; changeToShow = key}"/>
				</v-col>
			</v-row>
			<v-row v-else :key="i" class="mx-0">{{ key + ': ' + $tc('single_trad.ChangeField.becomes', 1, {change1: field[0], change2: field[1]} )}}</v-row>
		</template>

		<Dialog v-model="dialog" :title="$tc('single_trad.ChangeField.changes_on', 1, {item : changeToShow}) | firstCapital" width='600' @close="() => {dialog = false; changeToShow = ''}">
			<template v-slot:content>
				<v-row class="mx-0 pa-3" v-if="changeToShow !==''">
					<v-col class="pr-0 pb-0" v-if="Array.isArray(new_val[changeToShow][0])">
						<div class="pb-4 sub-title-2">{{ $t('single_trad.ChangeField.before') | firstCapital }}</div>
						<div v-for="item in new_val[changeToShow][0]" style="max-height: 500px; overflow-y: auto;" class="pb-2 field html-content caption-1" v-html="item.display_name ? item.display_name : item" />
					</v-col>
					<v-col class="pr-0 pb-0" v-else>
						<div class="pb-3 sub-title-2">{{ $t('single_trad.ChangeField.before') | firstCapital }}</div>
						<div style="max-height: 500px; overflow-y: auto;" class="pb-2 field html-content caption-1" v-html="new_val[changeToShow][0].display_name ? new_val[changeToShow][0].display_name : new_val[changeToShow][0]" />
					</v-col>
					<v-divider vertical class="my-2 mr-2" />
					<v-col class="pr-0 pb-0" v-if="Array.isArray(new_val[changeToShow][1])">
						<div class="pb-4 sub-title-2">{{ $t('single_trad.ChangeField.after') | firstCapital }}</div>
						<div v-for="item in new_val[changeToShow][1]"  style="max-height: 500px; overflow-y: auto;" class="pb-2 field html-content caption-1" v-html="item.display_name ? item.display_name : item" />
					</v-col>
					<v-col class="pr-0 pb-0" v-else>
						<div class="pb-4 sub-title-2">{{ $t('single_trad.ChangeField.after') | firstCapital }}</div>
						<div style="max-height: 500px; overflow-y: auto;" class="pb-2 field html-content caption-1" v-html="new_val[changeToShow][1].display_name ? new_val[changeToShow][1].display_name : new_val[changeToShow][1]" />
					</v-col>
				</v-row>
			</template>
		</Dialog>
	</div>
</template>

<script>
	export default {
		name: "ChangeField",
		props: {
            value: {},
			tooltip: {default: false},
			classes: {default: ''},
			expanded: {default: false},
		},
		components: {
			Dialog : () => import('@/components/ui/Dialog.vue'),
			Button : () => import('@/components/ui/Button.vue')
		},
		data(){
			return {
				dialog: false,
				changeToShow: ""
			}
		},
		computed: {
			new_val: {
				get(){
					return this.value
				},
				set(val){
					this.$emit('input', val)
				}
			}
		}
	}
</script>

<style scoped>
	.field.html-content >>> table {
        border-collapse: collapse !important;
        margin-bottom: 10px !important;
    }
    .field.html-content >>> td{
        border: 1px solid var(--v-black2white-base) !important;
        min-width: 30px !important;
        cursor: col-resize !important;
        padding: 5px;
    }
</style>
